<script setup lang="ts">
import { onMounted, onBeforeMount, watch, watchEffect, ref } from "vue";
import { useScroll } from "@vueuse/core";
import VTopButton from "@magnit/core/src/components/VTopButton/VTopButton.vue";
import VModalAdult from "@magnit/unit-catalog/src/components/VModalAdult.vue";
import useAdult from "@magnit/unit-catalog/src/composables/useAdult";
import { showAdultCookie } from "@magnit/unit-catalog/src/unit-catalog.types";
import {
  provideKeys,
  adultStorageKey,
} from "@magnit/unit-catalog/src/unit-catalog.vars";
import VCookiePolicyPopup from "@magnit/unit-cookie-policy/src/VCookiePolicyPopup.vue";
import VYaSlotErrorPopup from "@magnit/core/src/containers/VYaSlot/VYaSlotErrorPopup.vue";
import VLoginModal from "~/containers/Modals/VLoginModal/VLoginModal.vue";
import VFooter from "~/containers/VFooter/VFooter.vue";
import { useModalsStore } from "~/store/modals";

const nuxt = useNuxtApp();
initLogger(nuxt.$logger);
const router = useRouter();
const route = useRoute();
const otpStore = useOTPStore();
const authStore = useAuthStore();
const adultCookie = useCookie<showAdultCookie>(adultStorageKey);
const userStore = useUserStore();
const categoriesStore = useCategoriesStore();
const storesStore = useStoresStore();
const { loadSlotApiInit } = useSlotsStore();
const { requestShopCode, diffShopCode, isDiffShopCodeQuery } =
  storeToRefs(storesStore);
const slotsStore = useSlotsStore();
const modalsStore = useModalsStore();
const { approved, isDialogOpened, updateApprove } = useAdult(adultCookie.value);
const { kfpCid } = usePublicConfig();

const cookiePopupOpen = ref(false);
const showYaSlotPopup = ref(false);
const confirmAgeActive = ref(false);
const elWindow = ref<HTMLElement | Window | null>(null);
const windowScrollDirection = ref<"top" | "bottom">("bottom");

const rejectLabel = computed(() =>
  !confirmAgeActive.value && isGoodsProductPage.value
    ? "Нет, перейти на главную"
    : undefined,
);
const isGoodsProductPage = computed(
  () => RouteNames.ProductGoods === route.name,
);
const windowScroll = useScroll(elWindow);

const displayMainSlot = computed(() => !isDiffShopCodeQuery.value);
const skeletonComponent = computed(() => {
  if (
    route.name === "promo-catalog-category-categorySlug" ||
    route.name === "catalog-category-slug"
  ) {
    return resolveComponent("LazyVSkeletonCatalogPage");
  } else if (route.name === "popular") {
    return resolveComponent("LazyVSkeletonPopularPage");
  }
  return resolveComponent("LazyVSkeletonCommonPage");
});

provide(provideKeys.approved, approved);
provide(provideKeys.isDialogOpened, isDialogOpened);
provide(provideKeys.updateApprove, updateApprove);
provide(LocalProvidersKeys.ageConfirmationActive, confirmAgeActive);

await Promise.all(
  [
    storesStore.initializeSelectedStore(),
    !diffShopCode.value && categoriesStore.requestCategories(),
  ].filter(Boolean),
);
await Promise.all(
  [diffShopCode.value && categoriesStore.requestCategories()].filter(Boolean),
);

authStore.$onAction(({ name }) => {
  if (name === "logout") {
    otpStore.$reset();
    authStore.$reset();
    userStore.$reset();
    userStore.clearSavedUUID();
  }
});

const onReject = () => {
  if (!isGoodsProductPage.value || confirmAgeActive.value) return;
  router.push(Routes.Main);
};

onBeforeMount(() => {
  if (window.location.host === "cosmetic.magnit.ru") {
    document.cookie = "mg_at=; Max-Age=-99999999;";
  }
});

onMounted(async () => {
  if (authStore.state.authorized) {
    await userStore.getProfileSummary();
  }
});

onMounted(() => {
  elWindow.value = window;
  loadSlotApiInit();
  loadScript(`/scripts/${kfpCid.value}.das.obf.js`);
});

watch(
  () => authStore.state.authorized,
  async (authorized) => {
    if (authorized) {
      await userStore.getProfileSummary();
    }
  },
);
watch(requestShopCode, () => categoriesStore.requestCategories());

watchEffect(() => {
  if (windowScroll.isScrolling) {
    if (windowScroll.directions.top) {
      windowScrollDirection.value = "top";
    } else if (windowScroll.directions.bottom) {
      windowScrollDirection.value = "bottom";
    }
  }
});

watchEffect(() => {
  showYaSlotPopup.value =
    !slotsStore.slotPopupWasDisplayed &&
    !modalsStore.getActive() &&
    !cookiePopupOpen.value &&
    slotsStore.slotStatus === IYaSlotApiStatus.ERROR;
  if (showYaSlotPopup.value) {
    slotsStore.slotPopupWasDisplayed = true;
  }
});
</script>

<template>
  <Body
    class="body"
    :class="{
      'body_scrolled':
        windowScroll && windowScroll.y.value > LAYOUT_BODY_SCROLLED_PADDING,
      'body_scrolled-top':
        windowScroll
        && windowScroll.y.value > 0
        && windowScrollDirection === 'top',
      'body_scrolled-bottom':
        windowScroll
        && windowScroll.y.value > 0
        && windowScrollDirection === 'bottom',
    }"
  />
  <LayoutDefaultHeader />
  <div v-show="displayMainSlot" class="page">
    <slot />
  </div>
  <component :is="skeletonComponent" v-if="!displayMainSlot" />
  <VFooter />

  <VLoginModal />
  <VCookiePolicyPopup @visible="(visible) => (cookiePopupOpen = visible)" />
  <VYaSlotErrorPopup :show="showYaSlotPopup" />
  <VTopButton />
  <VModalAdult :reject-label="rejectLabel" @reject="onReject" />
</template>

<style lang="postcss">
.page {
  padding-top: 16px;
}
</style>
