<script setup lang="ts">
import { useRouter } from "vue-router";
import { cloneDeep } from "lodash-es";
import VFooter from "@magnit/unit-footer/src/VFooter.vue";
import {
  CLIENT_LINKS,
  MANAGEMENT_LINKS,
  COMPANY_LINKS,
} from "@magnit/unit-navigation/src/VNavigation.vars";
import type { ISubMenuLinks } from "@magnit/unit-navigation/src/VNavigation.types";
import { addUtmTags } from "@magnit/unit-navigation/src/VNavigation.utils";
import { Routes } from "~/utils/routes";

const config = useRuntimeConfig();
const router = useRouter();

const companylinks = cloneDeep(COMPANY_LINKS);
companylinks.links.splice(2, 0, {
  label: "Условия использования файлов cookies",
  url: Routes.CookiePolicy,
  onClick: (e: Event) => {
    e.preventDefault();
    router.push(Routes.CookiePolicy);
  },
});
const links: ISubMenuLinks = addUtmTags(
  [CLIENT_LINKS, MANAGEMENT_LINKS, companylinks],
  config.public.siteUrl,
  "layout",
  "footer",
);

const pwaLink =
  "https://my.magnit.ru/login?utm_source=web&utm_medium=cosmetic.magnit.ru&utm_campaign=footer&utm_content=qr_pwa_login";
const appLink =
  "https://redirect.appmetrica.yandex.com/serve/1109053982455637484/?utm_source=web&utm_medium=cosmetic.magnit.ru&utm_campaign=footer&utm_content=qr_app_install";
</script>

<template>
  <VFooter
    :links="links"
    project="magnit"
    class="footer"
    :pwa-link="pwaLink"
    :app-link="appLink"
  />
</template>

<style scoped lang="postcss">
.footer {
  margin-top: 64px;
}
</style>
