<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VYaCaptcha from "@magnit/core/src/containers/VYaCaptcha/VYaCaptcha.vue";
import VFieldControl from "~/components/VFieldControl.vue";
import InputPhone from "~/components/VInputPhone/VInputPhone.vue";
import { useOTPStore } from "~/store/otp";
import type { IInputChangeParams } from "~/components/VInputPhone/VInputPhone.types";

interface IState {
  phone: string;
  yacaptcha: string | null;
  valid: boolean;
}

const otpStore = useOTPStore();
const { send } = useAnalytics();
const { yandexCaptcha } = usePublicConfig();

const inputPhoneRef = ref<null | InstanceType<typeof InputPhone>>(null);
const yaCaptchaRef = ref<null | InstanceType<typeof VYaCaptcha>>(null);
const CAPTCHA_KEY = yandexCaptcha.value;

const state = reactive<IState>({
  phone: otpStore.state.phone || "",
  valid: Boolean(otpStore.state.phone),
  yacaptcha: null,
});

const isSubmitDisabled = computed(
  () => !state.valid || otpStore.status.code === "pending" || !state.yacaptcha,
);

const onYaCaptchaTokenChange = (token?: string) => {
  if (token) {
    state.yacaptcha = token;
    send("Login:Phone:Captcha:Click");
  }
};

const onPhoneChange = (params: IInputChangeParams) => {
  state.phone = params.value;
  state.valid = params.valid;
};

const onSubmit = () => {
  otpStore.getCode(state.yacaptcha, state.phone);
  send("Login:Phone:Submit");
};

onMounted(() => {
  inputPhoneRef.value?.focus();
});

watch(
  () => otpStore.status.registerError,
  (error) => {
    if (error === "captcha") {
      state.yacaptcha = null;
      yaCaptchaRef.value?.reset();
    }
  },
);

const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("Login:Phone:View");
  }
};
const onClickInput = () => {
  send("Login:Phone:Click");
};
</script>

<template>
  <div v-intersection-observer="onVisibility">
    <VFieldControl
      :has-error="otpStore.status.code === 'error'"
      error-text="Номер телефона введён некорректно"
    >
      <InputPhone
        ref="inputPhoneRef"
        :value="otpStore.state.phone"
        :disabled="otpStore.status.code === 'pending'"
        :has-error="otpStore.status.code === 'error'"
        @change="onPhoneChange"
        @keyup.enter="onSubmit"
        @click="onClickInput"
      />
    </VFieldControl>

    <VYaCaptcha
      ref="yaCaptchaRef"
      :captcha-key="CAPTCHA_KEY"
      class="phone-step__captcha"
      @token-changed="onYaCaptchaTokenChange"
    />
    <VButton
      full-width
      :loading="otpStore.status.code === 'pending'"
      :disabled="isSubmitDisabled"
      @click="onSubmit"
    >
      Далее
    </VButton>
  </div>
</template>

<style lang="postcss">
.phone-step__captcha {
  margin-bottom: 20px;
}
</style>
